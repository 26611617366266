import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USERS_MANAGE, ADMIN_USER_OFFERS_MANAGE, ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE } from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_USER_OFFER_DIAGNOSTIC_REPORT } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';

import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import Editor from 'Components/admin/userOfferDiagnosticReports/Editor';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import StyledComponent from 'Components/core/StyledComponent';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class AdminPageDiagnosticReportManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userOfferDiagnosticReport: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { userOfferDiagnosticReport, location, history } = this.props;

        return (
            <StyledComponent
                className="page-admin-diagnostic-report-manage"
                styles={require('./styles')}
            >  
                <Page
                    elementMode={true}
                    data={userOfferDiagnosticReport}
                >
                    {userOfferDiagnosticReport && userOfferDiagnosticReport.data && (
                        <PageContent
                            breadcrumbs={[{
                                visible: Boolean(userOfferDiagnosticReport.data.user),
                                to: withVariables(ADMIN_USERS_MANAGE.path, { id: userOfferDiagnosticReport.data.user && userOfferDiagnosticReport.data.user.id }),
                                label: `Użytkownik: ${userOfferDiagnosticReport.data.user && getUserFullName(userOfferDiagnosticReport.data.user).label}`,
                            },{
                                visible: Boolean(userOfferDiagnosticReport.data.userOffer),
                                to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: userOfferDiagnosticReport.data.userOffer && userOfferDiagnosticReport.data.userOffer.id }),
                                label: `Zamówienie: ${userOfferDiagnosticReport.data.userOffer && userOfferDiagnosticReport.data.userOffer.name}`,
                            },{
                                to: withVariables(ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.path, { id: userOfferDiagnosticReport.data.id }, { tab: TAB_DATA }),
                                label: 'Edytuj raport diagnostyczny',
                            }]}
                        >
                            <TabNavigation  
                                location={location}
                                history={history}
                                headline="Edytuj raport diagnostyczny"
                                subHeadline={userOfferDiagnosticReport.data.name}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={userOfferDiagnosticReport.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_USER_OFFER_DIAGNOSTIC_REPORT,
                                                dataId: userOfferDiagnosticReport.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>     
            </StyledComponent>
        );
    }
}
